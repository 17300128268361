import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div className="container container-last">
      <h1>
        Contact
        <br />
        Veejer Enterprises, Inc.
      </h1>
      <p>
        3701 Lariat Lane,
        <br />
        Garland, Texas 75042
      </p>
        <p>972-276-9642</p>
        <p><a href="mailto:vince@veejer.com">vince@veejer.com</a></p>
      <p>
        <a
          href="https://www.facebook.com/Veejer-Enterprises-149997568376570/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
      </p>
    </div>
  </Layout>
)

export default Contact
